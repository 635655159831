import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { CoverMain } from "../components/cover-main/cover-main"
import { Section } from "../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const Shortcodes = ({ block }) => {
  if (
    block.attributes.text === "crm-login" ||
    block.attributes.text === "crm-register" ||
    block.attributes.text === "crm-subscribe" ||
    block.attributes.text === "crm-forgot-password"
  ) {
    return (
      <Section>
        <div id="screen"></div>
      </Section>
    )
  }
}

const ForgotPassword = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ForgotPassPage {
      wordPress {
        page(id: "forgot-password", idType: URI) {
          title
          slug
          blocks {
            name
            ...CoreCoverBlock
            ...CoreShortcodeBlock
          }
        }
      }
    }
  `)

  const wordPress = data.wordPress.page

  useEffect(() => {
    setTimeout(() => {
      if (window.gigya) {
        window.gigya.accounts.showScreenSet({
          screenSet: "SML-RegistrationLogin",
          startScreen: "gigya-reset-password-screen",
          containerID: "screen",
        })
      }
    }, 1000)
  }, [])

  return (
    <Layout location={location} altNav>
      <Seo title="Forgot Password" />

      {wordPress?.blocks?.map((item, i) => {
        switch (item.name) {
          case "core/cover":
            return (
              <CoverMain
                key={`cover-${i}`}
                h1={wordPress && wordPress.title}
                img={item.attributes.url}
                alt={item.title}
                className="cover-md cover-detail"
              />
            )
          case "core/shortcode":
            return <Shortcodes block={item} />
        }
      })}
    </Layout>
  )
}

export default ForgotPassword
